import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import ThankYou from "./pages/ThankYou";
const router = createBrowserRouter([
  {
    path: "/:id",
    // exact: true,
    element: <ThankYou />,
  },
  {
    path: "*",
    element: (
      <ThankYou />
      // <Layout hideKeys>
      //   <div className="NotFoundBox">
      //     <div className="text-lg lg:text-xl font-regular text-center font-euclid notFound ">
      //       March 3nd, 2024 <br /> 12:00 p.m.
      //     </div>
      //     <div className="text-lg lg:text-xl leading-[63px] lg:leading-[70px] font-regular text-center font-euclid notFound">
      //       RIYADH, KSA
      //     </div>
      //     <div className="inviteSec font-hestina">
      //       Invite-only event <br /> Send your invite request to
      //       <br />
      //       <br />
      //       <span className="font-euclid ">press@raed.vc</span>
      //     </div>
      //   </div>
      // </Layout>
    ),
  },
]);
function App() {
  return <RouterProvider router={router} />;
}

export default App;
