import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { getAllData, getDataById, updateData } from "../services/google";
import Loader from "../components/Loader";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiCalendar } from "react-icons/bi";

function Home() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clickStatus, setClickStatus] = useState(null);
  const { id } = useParams();

  // console.log(id, user);

  // useEffect(() => {
  //   getAllData();
  // }, []);

  useEffect(() => {
    if (id)
      getDataById(id)
        .then(({ success, data }) => {
          if (success) setUser(data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    else {
      setLoading(false);
    }
  }, [id]);

  const postParticipated = useCallback(
    (participated) => {
      if (user?.participated !== participated) {
        setLoading(true);
        updateData({ id, participated })
          .then(({ success, data }) => {
            if (success) setUser(data);
            setClickStatus(participated);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [id, user]
  );

  const step2 = user?.participated === "YES";
  const step3 = user?.participated === "NO";

  // const step1 = !user?.participated;
  // const step2 = !clickStatus && user?.participated === "YES";
  // const step3 = !clickStatus && user?.participated === "NO";
  // const step4 = clickStatus === "YES";
  // const step5 = clickStatus === "NO";
  // const hideKeys = step4;

  if (loading) {
    return (
      <div className="absolute center top-[50%]">
        <Loader />
      </div>
    );
  }

  console.log(step2, step3, user, id, clickStatus, " sahi");

  return (
    <Layout
      onClick={postParticipated}
      {...{
        user,
        id,
        loading,
        // hideKeys, step1,
        step2,
        step3,
        // step5
      }}
    >
      {/* Step 1 */}
      {/* {step1 && (
        <>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px]  font-medium text-center font-euclidL">
            Welcome <strong>{user?.["Guest Name"]}</strong>,
            <br /> We are excited to see you in May 2nd, 2023 at Marriott Riyadh
            Diplomatic Quarter.
          </p>
          <p className="text-lg lg:text-xl pt-14  font-medium  leading-9 lg:leading-[50px] text-center mt-0 font-euclidL">
            Kindly RSVP below:
          </p>
        </>
      )} */}

      {/* Step 2 */}
      {step2 && (
        <>
          <div className="border border-[#00FF19] bg-[#EDFFEF] rounded-[5px] w-max px-16 py-1 mx-auto">
            <p className="green  text-xl lg:text-2xl leading-9 lg:leading-[50px] md:leading-[40px] font-euclid ">
              Status: Attending
            </p>
          </div>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px] font-medium  text-center pb-7 pt-7 font-euclidL">
            Thanks,{" "}
            <strong className="font-euclid">{user?.["Guest Name"]}</strong> for confirming your attendance.
          </p>
          <div className="buttonBox pb-8 ">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://maps.app.goo.gl/XfU6tLMoXf7LQvnD7"
            >
              <button className="leading-[30px] font-medium font-euclid">
                <HiOutlineLocationMarker /> Directions
              </button>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              data-id="Wp16730150"
              href="https://www.addevent.com/event/FK20134554"
            >
              <button className="leading-[30px] font-medium font-euclid">
                <BiCalendar /> Add to calendar
              </button>
            </a>
          </div>
          <p className="text-md lg:text-lg leading-9 lg:leading-[50px] font-medium text-center font-euclidL">
            If you would like to modify your attendance <br />
            click below:
          </p>
        </>
      )}

      {/* Step 3 */}
      {step3 && (
        <>
          <div className="border border-[#FF004D] bg-[#FFEDF5] rounded-[5px] w-max px-16 py-1 mx-auto">
            <p className="red  text-xl lg:text-2xl leading-9 lg:leading-[50px] md:leading-[40px] font-euclid ">
              Status: Not Attending
            </p>
          </div>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px] text-center font-medium pb-12 pt-7 font-euclidL">
            We will miss you <strong>{user?.["Guest Name"]}</strong>! 
          </p>
          <p className="text-md sm:text-lg lg:text-xl leading-9 lg:leading-[50px] text-center font-medium font-euclidL">
            If you would like to modify your attendance <br /> click below:
          </p>
        </>
      )}
      {/* Step 4 */}
      {/* {step4 && (
        <>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px]  font-medium text-center pb-7 font-euclidL">
            Fantastic! <br /> We are excited to meet you soon. <br />
          </p>

          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px] text-center font-euclidL">
            <strong className="font-euclid">Date:</strong>
            {" may 2nd, 2023 "}
          </p>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px] text-center font-euclidL">
            <strong className="font-euclid">Location:</strong>
            {" Marriott Riyadh DQ"}
          </p>

          <div className="buttonBox mt-8">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://goo.gl/maps/QhRnJk17dnRTSuPx5"
            >
              <button className="leading-[30px] font-medium font-euclid">
                <HiOutlineLocationMarker /> Directions
              </button>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              data-id="Wp16730150"
              href="https://www.addevent.com/event/Wp16730150"
            >
              <button className="leading-[30px] font-medium font-euclid">
                <BiCalendar /> Add to calendar
              </button>
            </a>
          </div>
        </>
      )} */}

      {/* Step 5 */}
      {/* {step5 && (
        <>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px]  font-medium text-center pb-7 font-euclid">
            What a loss! <br /> Sorry to hear that and we hope to see you soon
            in the future events.
          </p>
          <p className="text-lg lg:text-xl leading-9 lg:leading-[50px]  font-medium text-center font-euclid">
            If you will join us, kindly RSVP again:
          </p>
        </>
      )} */}
    </Layout>
  );
}

export default Home;
