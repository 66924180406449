import react, { useState, useEffect } from 'react'
import logo from "../assets/images/favicon24.png";
import logoText from "../assets/images/AnualMajlis24.png";
import { useParams } from "react-router-dom";
import { getDataById } from "../services/google";
import './style.css'


function ThankYou() {
    const [user, setUser] = useState(null);
    const [OuterBox, setOuterBox] = useState(window.innerHeight);
    const [innerBox, setInnerBox] = useState(window.innerHeight);
    const [innerBoxMargin, setInnerBoxMargin] = useState(0);
    const { id } = useParams();

    console.log(user?.["Guest Name"], id, 'here is data')

    // responsive inner height
    useEffect(() => {

        setInnerBox(window.innerWidth > 1280 ? window.innerHeight  : window.innerHeight  );
        setInnerBoxMargin(window.innerHeight < 660 ? 40 : 0 );

        function handleResize() {
            const adjustedinerBox = window.innerWidth > 1280 ? window.innerHeight - 88 : window.innerHeight - 64;
            setOuterBox(window.innerHeight);
            setInnerBox(window.innerHeight);
            setInnerBoxMargin(window.innerHeight < 660 ? 40 : 0 );
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])

    // get user by id
    useEffect(() => {
        if (id)
            getDataById(id)
                .then(({ success, data }) => {
                    if (success) setUser(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        else {
        }
    }, [id]);


    // style={{ height: innerBox }}
    // h-[calc(100vh-64px)] xl:h-[calc(100vh-88px)] 
    return (
        // <div className="customHeight bg-[#DA453C] bg-bg-repeated  bg-fixed bg-center bg-repeat bg-[length:7.125rem] bg-top py-8 xl:py-11 px-6 lg:px-8 xl:px-16 " style={{ height: OuterBox }}  >
            <div className="bg-white flex flex-col justify-around items-center h-lvh relative"  >
                <div className="border-img top-img"></div>
                <div className="border-img bottom-img"></div>
                <div className='flex justify-center items-center flex-col  ' style={{ height: innerBox}}>
                    <div style={{minHeight:400}} className='flex justify-between items-center flex-col  '>
                        <div className='w-2/3 md:w-1/3 '>
                            <img src={logo} alt="img" className="w-14 md:w-14 xl:w-20 mx-auto" />
                            <img src={logoText} alt="img" className="w-full mt-4" />
                        </div>
                        <div className="text-center px-4">
                            <h5 className="text-xl md:text-2xl lg:text-2xl xl:text-3xl font-euclid  md:leading-[70px] lg:leading-[104px]  xl:leading-[104px]">Thank you {user ? user?.["Guest Name"] : ''} for attending our annual Majlis.</h5>
                            <h5 className="mt-4 sm:mt-0 text-xl md:text-2xl lg:text-2xl xl:text-3xl font-euclid  md:leading-10 lg:leading-8  xl:leading-[62px] md:w-9/12 mx-auto ">Your attendance meant a lot to us, and we hope to see you again soon.</h5>
                        </div>
                        <h5 className="text-xl md:text-2xl lg:text-2xl xl:text-3xl font-euclid md:leading-[70px] lg:leading-[104px]  xl:leading-[104px]">Raed Team</h5>
                    </div>
                </div>

            </div>
        //   </div>
    )
}

export default ThankYou;