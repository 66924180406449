import axios from "axios";

// const BASE_URL = "/api/"; // for node js build
const BASE_URL = "https://survey.thecodingbuzz.com/api/";
// const BASE_URL = "http://localhost:5000/api/";
// const BASE_URL = "https://events.raed.vc/api/";

export { BASE_URL };

export const callApi = ({
  path,
  method = "GET",
  isForm,
  url = null,
  body = null,
  token = null,
}) => {
  let urlString = BASE_URL + path;
  let headers = {
    ...(isForm
      ? { "Content-Type": "multipart/form-data" }
      : {
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
  };
  let options = {
    method,
  };
  if (token) headers["authorization"] = "Bearer " + token;
  options.headers = headers;
  if (body) options.data = body;
  if (url) urlString = url;
  options.url = urlString;
  console.log(options);
  return axios(options).then((res) => {
    if (res.status === 200) {
      return res.data;
    }
    return { status: res.status, ...res.data };
  });
};
