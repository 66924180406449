import { callApi } from "./api";

export const getAllData = async () => {
  return callApi({ path: "rows" });
};
export const getDataById = async (id) => {
  return callApi({ path: `rows/${id}` });
};
export const updateData = async ({ id, ...data }) => {
  return callApi({ path: `rows/${id}`, body: data, method: "PUT" });
};
